import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ringBeratung from "../../assets/images/IMG_3716.jpg";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Product } from "../../../utils/types";
import {
  CustomNextArrowAltRel,
  CustomPrevArrowAltRel,
} from "../slickNavArrow/SlickNavArrow";
import "./FavSlider.css";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";

function RingSlider() {
  const [, setCurrentIndex] = useState(0);
  const sliderRef = useRef<any>(null);
  const [products, setProducts] = useState<Product[]>([]);

  const moveSlideForward = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    sliderRef.current?.slickNext(e);
  };

  const moveSlideBack = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    sliderRef.current?.slickPrev(e);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    centerMode: false,
    prevArrow: <></>,
    nextArrow: <></>,
    afterChange: (index: number) => setCurrentIndex(index),
    draggable: false,
    swipe: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let url = `/api/aeb/products?page=1&brand=Breuning&productType=DIAMANTSCHMUCK&productGroup=Ringe`;
        const response = await fetch(url);
        const data: Product[] = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Fehler beim Laden der Produkte:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="flex h-max w-full flex-col items-center justify-center pt-4">
      <div className="gap mb-8 flex w-[calc(100%-48px)] max-w-[1920px] flex-col items-center justify-between bg-gray-200 p-8">
        <img src={ringBeratung} className=" w-full lg:w-[700px]" alt="" />
      </div>
      <div className="mx-auto flex w-3/4 items-center justify-center pb-24 xl:w-[50%]">
        <CustomPrevArrowAltRel onClick={(e) => moveSlideBack(e)} />
        <Slider
          className="newsSlider__container--primary flex items-center justify-center"
          {...settings}
          ref={sliderRef}
        >
          {products &&
            products.map((favProduct, index) => (
              <div
                className="flex h-[500px] w-[400px] flex-col items-center justify-center"
                key={index}
              >
                <div className="newsSlider__container__slider__listContainer h-full">
                  <Link
                    className="g-navLinkAlt h-full"
                    to={`/${favProduct.productLine}/${favProduct.articleId}`}
                  >
                    <div
                      key={index}
                      className="service-card-container flex h-full items-center justify-center"
                    >
                      <div className="flex h-full flex-col items-center justify-between ">
                        <div className="flex w-3/4 flex-col items-center justify-center">
                          <div className="mx-auto">
                            <img
                              src={`data:image/jpeg;base64,${favProduct.mainImage}`}
                              className="mx-auto w-full pb-6 pt-6 "
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="mx-auto flex h-auto flex-col items-center justify-start">
                          <span className="mb-2 text-sm capitalize text-gray-400">
                            {favProduct.brand}
                          </span>
                          <h3 className="max-w-[30ch] text-center text-base">
                            {favProduct.productName}
                          </h3>
                          <span className="text-sm text-gray-400">
                            {favProduct.id}
                          </span>
                        </div>
                        <div className="flex w-full items-center justify-center pb-4">
                          <span className="w-1/2 border-t-[1px] border-gray-400 text-center text-xl font-bold">
                            {formatPrice(favProduct.price.toString())}€
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </Slider>
        <CustomNextArrowAltRel onClick={(e) => moveSlideForward(e)} />
      </div>
    </div>
  );
}

export default RingSlider;
