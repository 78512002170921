import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import jbLogo from "../../assets/icons/juwelier-bucher-dark.svg";
import backgroundLogin from "../../assets/images/bg/Union_D016.407.16.XXX.00_mood5.jpg";
import "./SignupPage.css";
import Select from "react-select";

function SignupPage() {
  const optionsCountry = [
    { value: "DEU", label: "Deutschland" },
    /* { value: "BEL", label: "Belgien" },
    { value: "BGR", label: "Bulgarien" },
    { value: "CYP", label: "Zypern" },
    { value: "CZE", label: "Tschechische Republik" },
    { value: "DNK", label: "Dänemark" },
    { value: "FIN", label: "Finnland" },
    { value: "FRA", label: "Frankreich" },
    { value: "GBR", label: "Großbritannien" },
    { value: "GRC", label: "Griechenland" },
    { value: "HUN", label: "Ungarn" },
    { value: "IRL", label: "Irland" },
    { value: "ITA", label: "Italien" },
    { value: "LIE", label: "Liechtenstein" },
    { value: "LTU", label: "Litauen" },
    { value: "LUX", label: "Luxemburg" },
    { value: "LVA", label: "Lettland" },
    { value: "NLD", label: "Niederlande" },
    { value: "POL", label: "Polen" },
    { value: "ROU", label: "Rumänien" },
    { value: "SRB", label: "Serbien" },
    { value: "SWE", label: "Schweden" },
    { value: "SVN", label: "Slowenien" },
    { value: "SVK", label: "Slowakei" }, */
  ];

  const [title, setTitle] = useState("");
  const [salutation, setSalutation] = useState("Keine Angabe");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [street, setStreet] = useState("");
  const [addressExtra, setAddressExtra] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password: string) => {
    const strongPasswordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
    );
    return strongPasswordRegex.test(password);
  };

  const handleSelectCountryChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      localStorage.setItem("country", selectedOption.value);
    }
  };

  const handleSignupSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!validatePassword(password)) {
      alert(
        "Das Passwort erfüllt nicht die Mindestanforderungen: Mindestens 8 Zeichen, mindestens eine Zahl, ein Klein- und ein Großbuchstabe.",
      );
      return;
    }

    if (password !== confirmPassword) {
      alert("Die Passwörter stimmen nicht überein!");
      return;
    }
    try {
      const response = await fetch("/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          salutation,
          title,
          firstName,
          lastName,
          email,
          password,
          streetAddress: street,
          addressExtra,
          postalCode,
          city,
          country: selectedCountry?.value, // Hier den Wert von selectedCountry verwenden
        }),
      });
      const data = await response.json();
      if (response.ok) {
        navigate("/sign-in");
      } else {
        alert(`Fehler bei der Registrierung: ${data.message}`);
      }
    } catch (error) {
      alert(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
      );
      console.error("Registrierungsfehler:", error);
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "100%",
      borderRadius: "0.375rem", // entspricht rounded-md
      border: "1px solid #e0e0e0",
      backgroundColor: "white",
      padding: "0.5rem 1rem", // py-3 px-6
      fontSize: "1rem", // text-base
      fontWeight: "500", // font-medium
      color: "#6B7280", // text-[#6B7280]
      boxShadow: "none",
      "&:hover": {
        borderColor: "#e0e0e0", // Verhalten beim Hover, optional
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0", // Entfernt das standardmäßige Padding innerhalb des Containers
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0px", // Entfernt den standardmäßigen Innenabstand
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "rgb(156 163 175)",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#6B7280",
    }),
    // Sie können weitere Stile für andere Teile der Komponente hier definieren
  };

  return (
    <div className="flex min-h-screen w-full items-center justify-center lg:h-screen lg:w-screen">
      <div className="relative flex min-h-screen w-full flex-col items-center justify-center bg-gray-50 xl:w-5/12">
        <Link to={"/"} className="w-[90%]">
          <img
            src={jbLogo}
            className="relative mr-auto w-32 py-8 xl:absolute xl:left-4 xl:top-4 xl:w-44"
            alt=""
          />
        </Link>
        <div className="flex w-max max-w-[90%] items-center justify-center xl:max-w-lg">
          <form
            className="flex w-full flex-col items-start justify-center gap-4"
            onSubmit={handleSignupSubmit}
          >
            <h1 className="font-GeistBold pb-2 text-left text-3xl text-[#121212]">
              Registrierung
            </h1>
            <div className="form-LableInput">
              <label htmlFor="country">Land</label>
              <Select
                options={optionsCountry}
                id="country"
                placeholder="Land wählen"
                value={selectedCountry}
                className="min-w-full text-left"
                styles={customStyles}
                onChange={handleSelectCountryChange}
                required
              />
            </div>
            <div className="form-LableInput">
              <label htmlFor="salutation">Anrede</label>
              <select
                className="loginInput placeholder:text-sm"
                id="salutation"
                value={salutation}
                onChange={(e) => setSalutation(e.target.value)}
                required
              >
                <option value="" disabled selected hidden>
                  Anrede
                </option>
                <option value="Keine Angabe">Keine Angabe</option>
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </select>
            </div>

            <div className="flex w-full flex-col items-center justify-center gap-4 xl:flex-row">
              <div className="form-LableInput">
                <label htmlFor="firstName">Vorname *</label>
                <input
                  className="loginInput placeholder:text-sm"
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Max"
                  required
                />
              </div>

              <div className="form-LableInput">
                <label htmlFor="lastName">Nachname *</label>
                <input
                  className="loginInput placeholder:text-sm"
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Mustermann"
                  required
                />
              </div>
            </div>

            <div className="form-LableInput">
              <label htmlFor="email">E-Mail *</label>
              <input
                className="loginInput placeholder:text-sm"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Muster@Mail.de"
                required
              />
            </div>

            <div className="form-LableInput">
              <label htmlFor="password">Passwort *</label>
              <input
                className="loginInput placeholder:text-sm"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                required
              />
              {/* <button type="button" className='sh__icon' onClick={togglePasswordVisibility}>
                {showPassword ? <p>&#10006;</p>
                  : <p>&#128065;</p>}
              </button> */}
            </div>

            <div className="form-LableInput">
              <label htmlFor="confirmPassword">Passwort wiederholen *</label>
              <input
                className="loginInput placeholder:text-sm"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                required
              />
            </div>

            <div className="form-LableInput">
              <label htmlFor="streetAddress">Straße und Hausnummer *</label>
              <input
                className="loginInput placeholder:text-sm"
                type="text"
                id="streetAddress"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="Musterstraße 42"
                required
              />
            </div>
            {/* <div className='form-LableInput'>
              <label htmlFor="addressExtra">Adresszusatz</label>
              <input
                className='loginInput placeholder:text-sm'
                type="text"
                id="addressExtra"
                value={addressExtra}
                onChange={(e) => setAddressExtra(e.target.value)}
                placeholder=''
              />
            </div> */}
            <div className="flex w-full items-center justify-center gap-4">
              <div className="flex w-4/12 flex-col items-start justify-start">
                <label htmlFor="city">PLZ *</label>
                <input
                  className="loginInput w-full placeholder:text-sm"
                  type="text"
                  id="postal"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder="00000"
                  required
                />
              </div>
              <div className="flex w-8/12 flex-col items-start justify-start">
                <label htmlFor="city">Ort *</label>
                <input
                  className="loginInput w-full placeholder:text-sm"
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Musterstadt"
                  required
                />
              </div>
            </div>
            {/* <div className='form-LableInput'>
              <label htmlFor="country">Land *</label>
              <CountryList
                value={country}
                onChange={(selectedOption: SingleValue<ICountryOption>) => setCountry(selectedOption ? selectedOption.value : '')}
              />
            </div> */}
            <div className="form-LableInput">
              <p className="text-sidebarGray-700 text-sm">* Pflichtfeld</p>
              <button
                type="submit"
                className="w-full rounded-xl border-2 border-blue-500 bg-blue-900 px-4 py-2 text-gray-50 hover:bg-blue-800"
              >
                Registrieren
              </button>
            </div>
          </form>
        </div>
      </div>
      <img
        src={backgroundLogin}
        className="hidden h-screen object-cover xl:block xl:w-7/12"
        alt=""
      />
    </div>
  );
}

export default SignupPage;
